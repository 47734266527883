module.exports = {
  publicPath: './',

  devServer: {
    proxy: {
      '/fzhd': {
        target: 'http://rg.wincitytech.com/',
        // target:'http://127.0.0.1:8082/',
        ws: false,
        //允许跨域
        changeOrigin: true,
        // //路径重写
        // pathReweite:{
        //   '^/api':''
        // }
      }
    }
  }
}