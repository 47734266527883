<template>
	<div class="upload">
		<div class="upload-box">
			<div class="upload-item" v-if="!isTJ">
				<div class="tk-box">
					<div class="tk-l-item">
						【影下大良】是大良街道宣传文体旅游和教育办公室联合“在大良”微信公众号、“顺峰山公园”微信公众号向公众征集大良随手拍照的活动，只要把你在大良拍下的相片，连同心情语句及个人联络资料发送到【影下大良】微网站，大良街道宣传文体旅游和教育办公室会从中挑选优秀作品，刊登于【影下大良】专栏中，与广大的大良市民及来访旅客分享。
					</div>
					<div class="tk-b-item">
						感谢您有意提交作品，提交前请先细阅以下条款。
					</div>
					<div class="tk-l-item">
						1.当您送出图片及其相关内容到此时，即表示您授权予大良街道宣传文体旅游和教育办公室、“在大良”微信公众号、“顺峰山公园”微信公众号的管理人员可无偿用于公益宣传，其中包括展示（涵盖此公众号以外的其它场合）、存放、修改及建立该内容的衍生作品的权利；
					</div>
					<div class="tk-l-item">2.本活动只接受以个人名义参加；</div>
					<div class="tk-l-item">
						3.您保证您拥有所有必需的版权及权限来授予上述的使用权利，且由您送出的图片及其相关内容必须为原创作品，如有侵犯版权事宜，所涉及的法律责任皆由投稿者承担，大良街道宣传文体旅游和教育办公室概不负责；
					</div>
					<div class="tk-l-item">
						4.每轮所有提交成功的作品会再由大良街道宣传文体旅游和教育办公室进行审核，其中只会选出部份优秀作品与大众分享；
					</div>
					<div class="tk-l-item">
						5.作品仅可作忠于被摄事物原状的艺术处理，例如亮度、对比度、色彩饱和度等，不接受同一场景原片电脑合成、接片合成、彩色转黑白的任何合成、添加、大幅度改变色彩等技术处理，作品请勿添加水印或边框；
					</div>
					<div class="tk-l-item">
						6.作品征集期内，同一参加者（微信账号）可无限次地递交不同的作品；
					</div>
					<div class="tk-l-item">
						7.参加者提供的个人资料，只用于本次活动联络；
					</div>
					<div class="tk-l-item">
						8.您投稿任何内容时，所需的通讯仪器等设备，以及与网络连接相关的合约月费、手机访问时产生的通信费等费用由您自行承担；
					</div>
					<div class="tk-l-item">
						9.如有任何争议，大良街道宣传文体旅游和教育办公室保留最终决定权，并保留修改活动细则的权利而不作个别通知。
					</div>
					<div class="tk-b-item">*本人已阅读并同意以上条款及细则*</div>
					<div class="y-n-btn">
						<van-radio-group v-model="radio" direction="horizontal">
							<van-radio name="1" @click="animationDown">是</van-radio>
							<van-radio name="2" @click="isAgree = false">否</van-radio>
						</van-radio-group>
					</div>
				</div>
				<div id="aa" class="sc-gn" v-if="isAgree">
					<div class="zp-bigbox">
						<div class="u-title">你的作品</div>
						<div class="zp-box">
							<!-- 图片或者视频选取模式，当accept为image | media 类型时设置capture可选值为camera可以直接调起摄像头 -->
							<van-uploader v-model="fileList" upload-text="添加图片" accept="image" :max-count="3" :after-read="afterRead" :before-delete="beforeDelete">
								<div class="zp-box-item">
									<div class="sc-btn">
										<img class="auto-img" src="../assets/images/sc.png" alt="" />
									</div>
									<div class="zp-tip" v-if="fileList.length === 0">
										*
										请选择上传图片，仅支持jpg、jpeg、png格式，请选择【原图】上传已保留清晰度。
									</div>
								</div>
							</van-uploader>
						</div>
					</div>

					<div class="upload-p-box">
						<div class="u-title">分享语句</div>
						<div class="u-input">
							<van-field v-model="shareSay" center type="textarea" maxlength="100" show-word-limit>
							</van-field>
						</div>
					</div>

					<div class="upload-p-box">
						<div class="u-title">拍摄地点</div>
						<div class="u-input">
							<van-field v-model="place" center> </van-field>
						</div>
					</div>

					<div class="upload-name-box">
						<div class="u-title">分享者真实姓名</div>
						<div class="u-input">
							<van-field v-model="realName" center> </van-field>
						</div>
					</div>
					<div class="upload-p-box">
						<div class="u-title">联系电话</div>
						<div class="u-input">
							<van-field class="phone-cell" v-model="phone" center type="digit" maxlength="11"
								:rules="[{ validator }]">
							</van-field>
						</div>
					</div>

					<div class="upload-p-box">
						<div class="u-title">联系邮箱</div>
						<div class="u-input">
							<van-field v-model="email" center> </van-field>
						</div>
					</div>

					<div class="tj-btn" @click="putIn()">
						<img class="auto-img" src="../assets/images/tijiao.png" alt="" />
					</div>
				</div>
			</div>

			<div class="upload-text" v-if="isTJ">
				<div class="u-bg-img">
					<img class="auto-img" src="../assets/images/bg.png" alt="" />
				</div>
				<div class="u-t">{{ tjText }}</div>
				<div class="back-home-btn" @click="back">
					<img class="auto-img" src="../assets/images/backIndex.png" alt="" />
				</div>
			</div>
			<van-dialog v-model="show" message="是否确定提交，一旦提交不可修改" show-confirm-button show-cancel-button
				@confirm="savePut" @cancel="show = false"></van-dialog>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {
		CompatSource
	} from "webpack-sources";

	export default {
		name: "Upload",
		data() {
			return {
				realName: "",
				phone: "",
				shareSay: "",
				place: "",
				email: "",
				fileList: [],
				isTJ: false,
				//手机号是否正确
				isPhone: false,
				tjText: "照片已上传，感谢你的分享！",
				//确定提交提示
				show: false,
				imageOne: "",
				imageTwo: "",
				imageThree: "",
				scImgList: [],
				index: 0,
				radio: "0",
				//弹上传功能
				isAgree: false,
				isSCCG: false,
			};
		},
		created() {
			// console.log(this.isTJ);
		},
		methods: {
			...mapMutations(["changeStateProperty"]),

			animationDown() {
				this.isAgree = true;
				let boxTop = document.querySelector(".tk-box");
				this.$nextTick(() => {
					document.querySelector(".upload-box").scrollTo({
						top: boxTop.offsetHeight + 35,
						behavior: "smooth",
					});
				});
			},

			beforeDelete(file, detail) {
				this.scImgList.splice(detail.index, 1);
				this.fileList.splice(detail.index, 1);
			},

			// 上传图片
			afterRead(file) {
				this.isSCCG = false;
				let param = new FormData();
				//通过append向form对象添加数据
				param.append("file", file.file);
				let config = {
					headers: {
						"Content-Type": "multipart/form-data", //必须要指定
					},
				};
				this.axios
					.post(
						"http://rg.wincitytech.com/prod-api/common/upload",
						// "http://rg.wincitytech.com/YXDL/f/poetry/userwx/upload",
						param,
						config
					).then((response) => {
						if (response.status === 200) {
							this.isSCCG = true;
						}
						let data = {
							name: response.data.newFileName,
							filePath: response.data.fileName
						};
						this.scImgList.push(data);
					});
			},

			validator(val) {
				this.isPhone = /^1[3-9]\d{9}$/.test(val);
			},

			putIn() {
				this.validator(this.phone);
				if (this.phone === "" || this.isPhone === false) {
					this.$toast.fail("请输入正确的手机号");
					return;
				}
				if (this.realName === "") {
					this.$toast.fail("请输入姓名");
					return;
				}
				if (this.place === "") {
					this.$toast.fail("请输入拍摄地点");
					return;
				}
				if (this.shareSay === "") {
					this.$toast.fail("请输入分享语句");
					return;
				}
				if (this.email === "") {
					this.$toast.fail("请输入邮箱");
					return;
				}
				if (this.fileList.length === 0) {
					this.$toast.fail("请上传图片");
					return;
				}
				if (!this.isSCCG) {
					return;
				}
				this.show = true;

				if (this.fileList[0]) {
					this.imageOne = this.scImgList[0].filePath;
				} else {
					this.imageOne = "";
				}
				if (this.fileList[1]) {
					this.imageTwo = this.scImgList[1].filePath;
				} else {
					this.imageTwo = "";
				}
				if (this.fileList[2]) {
					this.imageThree = this.scImgList[2].filePath;
				} else {
					this.imageThree = "";
				}
			},
			
			// 提交
			savePut() {
				this.axios({
					method: "get",
					url: "http://rg.wincitytech.com/prod-api/hc/image/yxdlImageSave",
					params: {
						openid: JSON.parse(sessionStorage.getItem("openId")),
						name: this.realName,
						phone: this.phone,
						imageOne: this.imageOne,
						imageTwo: this.imageTwo,
						imageThree: this.imageThree,
						address: this.place,
						sharingStatement: this.shareSay,
						email: this.email,
					},
				}).then((res) => {
					if (res.data.code == 200) {
						this.$toast("上传成功");
						this.phone = ""
						this.realName = ""
						this.fileList = []
						this.scImgList = []
						this.isTJ = true
						this.shareSay = ""
						this.place = ""
						this.email = ""
						this.isAgree = false;
					}
				})
				.catch((err) => {});
			},

			back() {
				this.isTJ = false;
				this.changeStateProperty({
					key: "isUpload",
					value: false
				});
				this.changeStateProperty({
					key: "isHome",
					value: true
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.upload-box {
		position: absolute;
		top: -60px;
		bottom: 110px;
		left: 40px;
		right: 40px;
		margin: auto;
		overflow-y: auto;
	}

	/deep/ .van-cell {
		padding: 3px;
		border: 1px solid #000;
	}

	.u-title {
		font-size: 18px;
		font-weight: bold;
		text-align: center;
		margin: 5px 0;
		color: #0088a6;
	}

	.zp-box {
		padding: 10px 15px;
		background-color: #fff;
		border: 1px solid #000;
	}

	.zp-box-item {
		display: flex;
		align-items: center;
	}

	.zp-tip {
		margin-left: 10px;
		font-size: 12px;
		font-weight: bold;
		color: #0088a6;
		flex: 1;
	}

	.sc-btn {
		width: 80px;
	}

	/deep/ .van-uploader__preview-image {
		width: 75px;
	}

	.tj-btn {
		width: 55%;
		margin: 20px auto;
	}

	.back-home-btn {
		width: 50%;
		margin: 15px auto 0;
	}

	.u-t {
		padding: 330px 0 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
	}

	.u-bg-img {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		z-index: -1;
	}

	.tk-b-item {
		font-size: 18px;
		font-weight: bold;
		color: #024788;
	}

	.tk-l-item {
		font-size: 16px;
		font-weight: bold;
		line-height: 22px;
		margin: 8px 0;
		color: #024788;
	}

	.y-n-btn {
		font-size: 16px;
		display: flex;
		font-weight: bold;
		justify-content: center;
		margin: 15px 0 30px;
	}

	.yx-rule {
		font-size: 13px;
		line-height: 30px;
		color: #024788;
	}
</style>