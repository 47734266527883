<template>
	<div class="home">
		<audio src="../assets/audio/1.mp3" ref="a" loop></audio>

		<audio :src="audioUrl" ref="audio"></audio>

		<div class="main-item" v-if="isHome">
			<div class="item-list">
			<!-- <div class="yx-rule">
				【影下大良】是大良街道宣传文体旅游办公室向公众征集大良随手拍照的活动，只要把你在大良拍下的相片，连同心情语句及个人联络资料发送到【影下大良】微网站，宣传文体旅游办公室会从中挑选优秀作品，刊登于每周发布的【凤城之美】专栏中，与广大的大良市民及来访旅客分享。
			</div> -->
			</div>

			<div class="one upload" @click="showUpload">
				<img class="auto-img" src="../assets/images/tougao.png" alt="" />
			</div>
			<div class="one gwtw" @click="showGW">
				<img class="auto-img" src="../assets/images/gwtw.png" alt="" />
			</div>
		</div>
		<!-- <div class="zbdw" v-if="isHome">
			<img class="auto-img" src="../assets/images/zbdw.png" alt="" />
		</div> -->

		<Upload v-if="isUpload" />
	</div>
</template>
<script>
	import Upload from "../components/Upload";
	import {
		mapState,
		mapMutations
	} from "vuex";
	import vueConfig from "../../vue.config";
	export default {
		name: "Home",
		components: {
			Upload,
		},

		computed: {
			...mapState([
				"isHome",
				"audioUrl",
				"audioEle",
				"isa",
				"isUpload",
			]),
		},

		created() {
			sessionStorage.removeItem("openId");
			this.accredit();
			this.getTime();
		},

		beforeUpdate() {
			if (this.isa) {
				this.$refs.a.play();
			}
		},

		data() {
			return {
				openId: "",
				//显示规则
				isRule: false,
				//查看发起人队伍列表id
				initiatorOpenIds: "",
				//倒计时
				time: null,
				code: "",
			};
		},

		methods: {
			...mapMutations(["changeStateProperty"]),

			showUpload() {
				this.changeStateProperty({
					key: "isa",
					value: false
				});
				this.changeStateProperty({
					key: "isHome",
					value: false
				});
				this.changeStateProperty({
					key: "isUpload",
					value: true
				});
			},

			play() {
				this.changeStateProperty({
					key: "isa",
					value: !this.isa
				});
				if (this.isa) {
					this.$refs.a.play();
				} else {
					this.$refs.a.pause();
				}
			},

			//授权
			accredit() {
				let Id = sessionStorage.getItem("openId");

				if (!Id) {
				  sessionStorage.setItem(
				    "openId",
				    JSON.stringify(this.$route.query.openid)
				  );
				}
			},

			//显示规则
			showRule() {
				this.changeStateProperty({
					key: "isa",
					value: true
				});
				this.changeStateProperty({
					key: "isHome",
					value: false
				});
				this.changeStateProperty({
					key: "isTeamIcon",
					value: false
				});
				setTimeout(() => {
					this.isRule = true;
				}, 150);
			},

			//规则返回首页
			rback() {
				this.isRule = false;
			},

			getTime() {
				let h = new Date().getHours();
				let m = new Date().getMinutes();
				let s = new Date().getSeconds();
				let now = h + m / 60 + s / 60 / 60;
				this.time = (24 - now) * 60 * 60 * 1000;
			},

			showGW() {
				window.location.href ="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzA5ODAxMDAwNA==&action=getalbum&album_id=2838517102494859266#wechat_redirect";
			}
		},
	};
</script>

<style lang="less" scoped>
	.home {
		position: absolute;
		top: 100px;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 1;

		.item-list {
			margin: 180px auto 0;
			padding: 0 57px 30px;
		}
	}

	.yx-rule {
		font-size: 13px;
		line-height: 30px;
		color: #024788;
	}

	.one {
		width: 45%;
		margin: auto;
	}

	.gwtw {
		margin-top: 8px;
	}

	.count-down {
		position: absolute;
		top: 0;
		bottom: 40px;
		display: flex;
		align-items: center;
		left: 13px;
		margin: auto;
		transform: rotateZ(-10deg);
	}

	/deep/ .van-count-down {
		display: inline-block;
		font-size: 14px;
		font-weight: bold;
	}
</style>